@font-face {
  font-family: "Avenir Bold";
  src: url("./Assets/Fonts/Avenir\ Next\ LT\ Pro\ Bold.otf");
}
@font-face {
  font-family: "Avenir Medium";
  src: url("./Assets/Fonts/Avenir\ Medium.ttf");
}
@font-face {
  font-family: "Avenir";
  src: url("./Assets/Fonts/Avenir\ LT\ Pro\ 45\ Book.ttf");
}

.ant-dropdown-menu {
  height: 500px;
  width: 500px;
  border-radius: 30px !important;
  border: 3px solid #ff7f00 !important;
  background: #e4e4e4 !important;
  box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.15) !important;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.app {
  background-color: #000;
  background-image: url("./Assets/Background-Gradient.png");
  background-size: cover;
  background-position: top;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.ant-spin .ant-spin-dot-item {
  background-color: #ff7f00 !important;
}

.ant-dropdown-menu .ant-dropdown-menu-title-content {
  display: flex;
  justify-content: center;
}

.ant-collapse.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  display: none;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 40px;
}

.ant-modal-mask {
  backdrop-filter: blur(10px);
}

.ant-empty-description {
  color: #fff !important;
}

::-webkit-scrollbar {
  height: 0px !important;
  width: 10px !important;
}
::-webkit-scrollbar-track {
  background: #000;
}
::-webkit-scrollbar-thumb {
  background: #ff7f00;
  border-radius: 5px !important;
}

.Toastify__toast-theme--dark {
  border-radius: 15px !important;
  background: #ff7f00 !important;
  /* background: black; */
  color: black !important;
  /* color:#F6BD31 !important ; */
  text-align: center;
  text-transform: uppercase !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 27px; /* 150% */
}

.toast-position {
  top: 150px !important;
}

.notification-popover .ant-popover-inner {
  margin-right: 30px;
  border-radius: 0;
  padding: 0;
  background-color: #000;
  background-image: url("./Assets/Logo/Mask\ group.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200px;
}

.notification-popover .ant-popover-arrow {
  --antd-arrow-background-color: #000;
}

.notification-popover .ant-popover-title {
  color: #fff;
  padding: 20px 0 0 30px;
  font-size: 20px;
  font-family: 'Avenir Bold';
}